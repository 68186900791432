import React, { useState, useEffect } from 'react';
import { FaGooglePlay, FaApple } from 'react-icons/fa';
import { IconContext } from "react-icons";
import '../styles/Popup.css';

const getFirstWordAndRemove = (textString) => {
  const firstSpaceIndex = textString.indexOf(' ');

  if (firstSpaceIndex !== -1) {
    const firstWord = textString.substring(0, firstSpaceIndex);
    const remainingString = textString.substring(firstSpaceIndex + 1);

    return [firstWord, remainingString];
  } else {
    return [null, ""];
  }
};

const makeUrlsClickable = (text) => {
  const urlRegex = /(https:\/\/[^\s]+)/gi;
  const parts = text.split(urlRegex);

  return parts.map((part, index) => {
    if (part.match(urlRegex)) {
      return (
        <a
          key={index}
          href={part}
          target="_blank"
          rel="noopener noreferrer"
          className="ClickableLink"
        >
          {part}
        </a>
      );
    } else {
      return <>{part}</>;
    }
  });
};

const Popup = ({ data, onClose }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [firstWord, remainingString] = getFirstWordAndRemove(data.description);
  const [videoUrl, setVideoUrl] = useState('');

  useEffect(() => { 
    if (data.video) {
      const videoUrl = require('../assets/videos/' + data.video);
      setVideoUrl(videoUrl);
    };
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
      )
    ) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  const projectView = () => (

    <div key={data.title} className='project-container'> 
      <div className='project-header'>
        <i className='close-icon'onClick={onClose} >close</i>
        <span className='project-name'>{data.title}</span>
      </div>
      <div className={`project ${isMobile ? 'mobile' : ''}`} onClick={(e) => e.stopPropagation()}> 
        <div className={`video-container ${isMobile ? 'mobile' : ''}`} >
          {data.video && <iframe src={videoUrl} frameborder="0" title='video'/> }
        </div>
        <div className='project-description-container'>
          <span>{firstWord}</span>
          {paragraphs}
        </div>
        {(data.appleStore || data.googlePlay ) && (
          <div className='icon-container'>
            <IconContext.Provider value={{ color: 'gray', size: '28px', className: 'Icons' }}>
              <a className='icon' href={data.appleStore}><FaApple/></a>
              <a className='icon' href={data.googlePlay}><FaGooglePlay/></a>
            </IconContext.Provider>
          </div>
        )}
      </div>
    </div>
  );
  
  const paragraphs = remainingString.split('\n').map((item, i) => (
    <React.Fragment key={i}>
      {makeUrlsClickable(item)}
      <br />
      <br />
    </React.Fragment>
  ));

  return projectView();
};

export default Popup;
