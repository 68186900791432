import React,{ useState } from 'react';
import Popup from '../components/Popup';

import '../styles/Card.css';

import assets from '../assets/assets.json';

const Card = ( props ) => {
    const [showPopup, setShowPopup] = useState(false);
    const [popupData, setPopupData] = useState(null);

    const handlePopup = (m, e) => {
        e.stopPropagation();
        setShowPopup(true);
        setPopupData(m);
        document.body.style.overflow = "hidden"
    };

    const closePopup = () => {
        setShowPopup(false);
        document.body.style.overflow = "auto"
      };


    const project = assets.projects.find((project) => project.id === props.object.id);
    let imagePath = require('../assets/images/placeholder.webp');
    
    if (project.image !== "") {
        imagePath = require('../assets/images/' + project.image);
    }

    return(
        <>
        
            <div className="Card">
                <div>
                    <div className="CardBody">
                        <h2>{props.object.title}</h2>
                        <p> {props.object.shortDes}
                        </p>
                        {props.object.description 
                            ? <div className="LearnMore" onClick={(event) => handlePopup(props.object, event)}>Learn more</div>
                            : <span className='ComingSoon'>Coming Soon</span>
                        }
                    </div>
                    <div className='CardDiv'>
                    </div>
                    <div className='CardImg'>
                        <img src={imagePath} alt="thumbnail"/>
                    </div>
                </div>
            </div>
            {showPopup && popupData && (
                <Popup
                data={popupData}
                onClose={() => closePopup()}
                />
            )}
        </>
    );
};

export default Card;