import React from 'react';

import Social from './Social';
import Pages from './Pages';
import Logo from './logo';

import '../styles/Menu.css'

function Menu() {

  return (
    <div className="menu">
      <div className='menu-container'>
        <Logo/>
        <Pages/>
        <Social/>
      </div>
    </div>
  );
}

export default Menu;
