import React from 'react';
import Card from '../components/Card';

import '../styles/Projects.css';

import data from '../assets/assets.json';

const Projects = () => {
  return ( 
    <>
      <div className='DescriptionContainer'>
        <h2>{data.projectsPage.title}</h2>
        <span>
          <div>{data.projectsPage.para01}</div>
          <br/>
          <div>{data.projectsPage.para02}</div>
          <br/>
          <div>{data.projectsPage.para03}</div>
          <br/>
          <div>{data.projectsPage.para04}</div>
        </span>
      </div>
      <div className='ProjectGrid'>
        {data.projects.map((project, index) => ( <Card key={index} object={project}/> ))} 
      </div>
    </>
  );
};

export default Projects;
