import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, {useState, useEffect} from 'react';

import data from '../assets/assets.json';

import Home from '../pages/Home';
import Projects from '../pages/Projects';
import About from '../pages/About';

import Menu from '../components/Menu';

import '../styles/Website.css';

const Header = () => {
  const [isMobile, setIsMobile] = useState(false);
  // const hues = [0, 90, 180, 270]; // Different hue-rotate values for different colors
  // const [currentHueIndex, setCurrentHueIndex] = useState(0);

  // const handleNextHue = () => {
  //   setCurrentHueIndex((prevIndex) => (prevIndex+1) % hues.length);
  // };

  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
      )
    ) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    
  }, []);


  return (
    <Router>
      <div className='container disable-scroll'>
        <div className='website-container'>
            <div className={`header ${isMobile ? 'mobile' : ''}`}>
              <Menu/>
              <div className={`header-title ${isMobile ? 'mobile' : ''}`}>
                <span>{data.title01}<div>{data.title02}</div>{data.title03}<div></div>{data.title04}</span>
              </div>
            </div>
            <div className={`site-main ${isMobile ? 'mobile' : ''}`}>
              <Routes>
                <Route exact path="/" element={<Home />}/>
                <Route exact path="/Projects" element={<Projects />} />
                <Route exact path="/about" element={<About />} />
              </Routes>
            </div>
        </div>
      </div>
    </Router>
  );
};

export default Header;