// Footer.js
import { NavLink } from 'react-router-dom';
import React, {useState, useEffect} from 'react';

import { FaHouseDamage } from 'react-icons/fa';
import { GrProjects } from 'react-icons/gr';
import { SiAboutdotme } from 'react-icons/si';
import { IconContext } from "react-icons";

import '../styles/Pages.css'; 

const Pages = () => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
      )
    ) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  return (
    <div className='Pages'>
      <ul>
        <li>
          <NavLink className={({ isActive }) => (isActive ? "LinkActive" : "")} to="/">
          {isMobile 
          ? <IconContext.Provider value={{ color: 'gray', size: '22px', className: 'Icons' }}>
              <FaHouseDamage/>
            </IconContext.Provider> 
          : <div>Home</div>
          }
          </NavLink>
        </li>
        <li>
          <NavLink className={({ isActive }) => (isActive ? "LinkActive" : "")} to="/Projects">
          {isMobile 
          ? <IconContext.Provider value={{color: 'gray', size: '18px', className: 'Icons' }}>
              <GrProjects/>
            </IconContext.Provider> 
          :  <div>Projects</div>
          } 
          </NavLink>
        </li>
        <li>
          <NavLink className={({ isActive }) => (isActive ? "LinkActive" : "")} to="/about">
          {isMobile 
          ? <IconContext.Provider value={{ color: 'gray', size: '28px', className: 'Icons' }}>
              <SiAboutdotme/>
            </IconContext.Provider> 
          : <div>About Me</div>
          } 
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Pages;
