// Home.js

import React from 'react';

import '../styles/Home.css';

import data from '../assets/assets.json'

const getFirstWordAndRemove = (textString) => {
  const firstSpaceIndex = textString.indexOf(' ');

  if (firstSpaceIndex !== -1) {
    const firstWord = textString.substring(0, firstSpaceIndex);
    const remainingString = textString.substring(firstSpaceIndex + 1);

    return [firstWord, remainingString];
  } else {
    return [null, ""];
  }
};

const Home = () => {
  const [firstWord, remainingString] = getFirstWordAndRemove(data.welcome01);
  const paragraphs = remainingString.split('\n').map((item, i) => (
    <React.Fragment key={i}>
      <p style={{ display: 'inline' }}>{item}</p>
      <br />
      <br />
    </React.Fragment>
  ));

  return (
    <>
    <div className='Background'></div>
    <div className='Home'>
      <ul>
        <li><span>{firstWord}</span>{paragraphs}</li>
        <li><div>{data.welcome02}</div></li>
        <br/>
        <li><div>{data.welcome03}</div></li>
        <br/>
        <li><div>{data.welcome04}</div></li>
        <br/>
        <li><div>{data.welcome05}</div></li>
    </ul>
    </div>
    </>
  );
};

export default Home;