// Footer.js

import React from 'react';
import { FaLinkedin, FaGithub } from 'react-icons/fa'
// import { MdOutlineAlternateEmail } from 'react-icons/md'
import { IconContext } from "react-icons";

import '../styles/Social.css'; // Create a separate CSS file for Footer styles

const Social = () => {
  return (
       <div className='social'>
        <IconContext.Provider value={{ color: "gray" }}>
            <a href='https://github.com/FinSisu'><FaGithub/></a>
            <a href="https://www.linkedin.com/in/mikakunto/"><FaLinkedin/></a>
            {/* <a href='https://www.instagram.com/finsisu17/'><FaInstagram/></a> */}
        </IconContext.Provider>
      </div>
  );
};

export default Social;