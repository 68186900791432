// Header.js
import React, { useEffect, useState } from 'react';

import '../styles/logo.css'

const Logo = () => {
  const [deviceType, setDeviceType] = useState("");

  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
      )
    ) {
      setDeviceType("mobile");
    } else {
      setDeviceType("desktop");
    }
  }, []);
  
  return (
    <div className='svg-container'>
      {deviceType === 'desktop' ?
        <svg width="175" height="40" xmlns="http://www.w3.org/2000/svg">
          <text id="M" x="10" y="25" fontSize="40">M</text>
          <text id="i" x="40" y="25" fontSize="40">i</text>
          <text id="k" x="45" y="25" fontSize="40">k</text>
          <text id="a" x="60" y="25" fontSize="40">a</text>
          <text id="K_2" x="85" y="25" fontSize="40">K</text>
          <text id="u" x="105" y="25" fontSize="40">u</text>
          <text id="n" x="125" y="25" fontSize="40">n</text>
          <text id="t" x="145" y="25" fontSize="40">t</text>
          <text id="o" x="155" y="25" fontSize="40">o</text>
      </svg>
      : 
      <svg width="175" height="40" xmlns="http://www.w3.org/2000/svg">
          <text id="M" x="10" y="25" fontSize="28">M</text>
          <text id="K_2" x="32" y="25" fontSize="28">K</text>
      </svg>
    }
    </div>
  );
};

export default Logo;