// About.js

import React from 'react';

import data from '../assets/assets.json';

import '../styles/About.css';

const About = () => {

  return (
    <>
      <div className='AboutMeContainer'>
        <h2>{data.aboutme.title}</h2>
        <span>
          <div>{data.aboutme.summary}</div>
          <br/>
          <div>{data.aboutme.para01}</div>
          <br/>
          <div>{data.aboutme.para02}</div>
          <br/>
          <div>{data.aboutme.para03}</div>
          <br/>
          <div>{data.aboutme.para04}</div>
          <br/>
          <div>{data.aboutme.para05}</div>
          <br/>
          <div>{data.aboutme.para06}</div>
          <br/>
          <div>{data.aboutme.para07}</div>
          <br/>
          <div>{data.aboutme.para08}</div>
          <br/>
          <div>{data.aboutme.para09}</div>
          <br/>
          <div>{data.aboutme.para10}</div>
        </span>
      </div>
    </>
  );
};

export default About;
